import { AppsRouteName } from '@sewan/core';
import { DokoLogo } from '@ui-kit/logo';
import React from 'react';

import MenuItem from '../MenuItem';
import { SOFTPHONES } from '#constants';

const DokoMenuItem = () => (
    <MenuItem
        logo={<DokoLogo height={48} width={48} />}
        pathname={`/${AppsRouteName.DOKO_ADVERTISEMENT}`}
        type={SOFTPHONES.DOKO}
    />
);

export default DokoMenuItem;
