import { AppsRouteName, selfcareModules, DokoAppsRouteName } from '@sewan/core';
import { sidebarIcons, HomeIcon, CallTransferIcon, PhoneSettingsIcon } from '@ui-kit/icon';
import React, { lazy } from 'react';

import { Webex, DokoAds } from './components/Softphones';

const CallLogs = lazy(() => import('@sewan/calllogs'));
const CallSettings = lazy(() => import('@sewan/callsettings'));
const Consumptions = lazy(() => import('@sewan/consumptions'));
const Contacts = lazy(() => import('@sewan/contacts'));
const Dashboard = lazy(() => import('@sewan/dashboard'));
// const Documentation = lazy(() => import('@sewan/documentation'));
const Login = lazy(() => import('@sewan/login'));
const Phones = lazy(() => import('@sewan/phones'));
const Profile = lazy(() => import('@sewan/profile'));
const Voicemails = lazy(() => import('@sewan/voicemails'));

const {
    PhoneBookIcon,
    VoicemailBoxIcon,
    CallLogsIcon,
    ConsumptionsIcon,
    SettingsIcon,
} = sidebarIcons;

export const PREVIOUS_PATH_KEY = 'previousPath';

export const configNoAuthRoutes = [
    {
        path     : AppsRouteName.LOGIN,
        component: <Login />,
        exact    : true,
        transKey : 'pageTitle.login',
    },
];

export const configDokoRoutes = [
    {
        path     : DokoAppsRouteName.TELEPHONY_SETTINGS,
        component: <Phones />,
        exact    : true,
        transKey : 'pageTitle.phones',
        module   : selfcareModules.MODULE_SELFCARE_PHONES,
    },
    {
        path     : DokoAppsRouteName.CALL_FORWARDING,
        component: <CallSettings />,
        exact    : true,
        transKey : 'pageTitle.callSettings',
        module   : selfcareModules.MODULE_SELFCARE_CALL_MANAGEMENTS,
    },
    {
        path     : DokoAppsRouteName.VOICEMAILS_MESSAGES,
        component: <Voicemails />,
        exact    : true,
        transKey : 'pageTitle.voicemail',
        module   : selfcareModules.MODULE_SELFCARE_VOICEMAILS,
    },
    {
        path     : DokoAppsRouteName.VOICEMAILS_LANDLINE_SETTINGS,
        component: <Voicemails />,
        exact    : true,
        transKey : 'pageTitle.voicemail',
        module   : selfcareModules.MODULE_SELFCARE_VOICEMAILS,
    },
];

export const configRoutes = [
    {
        path        : AppsRouteName.HOME,
        component   : <Dashboard />,
        exact       : true,
        label       : 'nav.applications.dashboard',
        icon        : HomeIcon,
        transKey    : 'pageTitle.dashboard',
        mustbeHidden: {
            mobileOnly         : false,
            noPhone            : true,
            production         : false,
            hostedTelephonyOnly: false,
        },
        availableInMenu: true,
    },
    {
        path        : AppsRouteName.CALL_FORWARDING,
        component   : <CallSettings />,
        label       : 'nav.applications.callSettings',
        icon        : CallTransferIcon,
        transKey    : 'pageTitle.callSettings',
        mustbeHidden: {
            mobileOnly         : true,
            noPhone            : true,
            production         : false,
            hostedTelephonyOnly: false,
        },
        availableInMenu: true,
        module         : selfcareModules.MODULE_SELFCARE_CALL_MANAGEMENTS,
    },
    {
        path        : AppsRouteName.TELEPHONY_SETTINGS,
        component   : <Phones />,
        label       : 'nav.applications.phones',
        icon        : PhoneSettingsIcon,
        transKey    : 'pageTitle.phones',
        mustbeHidden: {
            mobileOnly: true,
            noPhone   : true,
            production: false,
        },
        hasESimCard    : true,
        availableInMenu: true,
        module         : selfcareModules.MODULE_SELFCARE_PHONES,
    },
    {
        path        : AppsRouteName.VOICEMAILS_MESSAGES,
        component   : <Voicemails />,
        label       : 'nav.applications.voicemail',
        icon        : VoicemailBoxIcon,
        transKey    : 'pageTitle.voicemail',
        mustbeHidden: {
            mobileOnly         : true,
            noPhone            : true,
            production         : false,
            hostedTelephonyOnly: false,
        },
        availableInMenu: true,
        module         : selfcareModules.MODULE_SELFCARE_VOICEMAILS,
    },
    {
        path        : AppsRouteName.VOICEMAILS_MOBILE_SETTINGS,
        component   : <Voicemails />,
        label       : 'nav.applications.voicemail',
        icon        : VoicemailBoxIcon,
        transKey    : 'pageTitle.voicemail',
        mustbeHidden: {
            mobileOnly         : true,
            noPhone            : true,
            production         : false,
            hostedTelephonyOnly: true,
        },
        availableInMenu: false,
        module         : selfcareModules.MODULE_SELFCARE_VOICEMAILS,
    },
    {
        path        : AppsRouteName.VOICEMAILS_LANDLINE_SETTINGS,
        component   : <Voicemails />,
        label       : 'nav.applications.voicemail',
        icon        : VoicemailBoxIcon,
        transKey    : 'pageTitle.voicemail',
        mustbeHidden: {
            mobileOnly         : true,
            noPhone            : false,
            production         : false,
            hostedTelephonyOnly: false,
        },
        availableInMenu: false,
        module         : selfcareModules.MODULE_SELFCARE_VOICEMAILS,
    },
    //  Not needed anytime soon either in dev or production
    // {
    //     path        : AppsRouteName.DOCUMENTS,
    //     component   : <Documentation />,
    //     label       : 'nav.applications.documentations',
    //     icon        : FilesIcon,
    //     transKey    : 'pageTitle.documentations',
    //     mustbeHidden: {
    //         mobileOnly         : true,
    //         noPhone            : true,
    //         production         : true,
    //         hostedTelephonyOnly: true,
    //     },
    //     availableInMenu: false,
    // },
    {
        path        : AppsRouteName.CALL_LOGS,
        component   : <CallLogs />,
        label       : 'nav.applications.callLogs',
        icon        : CallLogsIcon,
        transKey    : 'pageTitle.callLogs',
        mustbeHidden: {
            mobileOnly: true,
            noPhone   : true,
        },
        availableInMenu: true,
        module         : selfcareModules.MODULE_SELFCARE_CALL_LOGS,
    },
    {
        path        : AppsRouteName.CONSUMPTION_TRACKING,
        component   : <Consumptions />,
        label       : 'nav.applications.consumption',
        icon        : ConsumptionsIcon,
        transKey    : 'pageTitle.consumption',
        mustbeHidden: {
            mobileOnly         : false,
            noPhone            : true,
            hostedTelephonyOnly: true,
        },
        availableInMenu: true,
        module         : selfcareModules.MODULE_SELFCARE_CONSUMPTION,
    },
    {
        path        : AppsRouteName.CONTACTS,
        component   : <Contacts />,
        label       : 'nav.applications.contacts',
        icon        : PhoneBookIcon,
        transKey    : 'pageTitle.contacts',
        mustbeHidden: {
            mobileOnly         : false,
            noPhone            : true,
            hostedTelephonyOnly: false,
        },
        availableInMenu: true,
        module         : selfcareModules.MODULE_SELFCARE_CONTACTS,
    },
    {
        path        : AppsRouteName.PROFILE,
        component   : <Profile />,
        label       : 'nav.applications.profile',
        icon        : SettingsIcon,
        transKey    : 'pageTitle.profile',
        mustbeHidden: {
            mobileOnly         : false,
            noPhone            : false,
            hostedTelephonyOnly: false,
        },
        availableInMenu: false,
    },
];

export const SOFTPHONES = {
    WEBEX: 'webex',
    DOKO : 'doko',
};

export const configRoutesSoftphones = {
    [SOFTPHONES.DOKO]: {
        path        : AppsRouteName.DOKO_ADVERTISEMENT,
        component   : <DokoAds />,
        transKey    : 'pageTitle.dokoAdvertisement',
        mustbeHidden: {
            mobileOnly: true,
            noPhone   : true,
        },
        availableInMenu: false,
    },
    [SOFTPHONES.WEBEX]: {
        path        : AppsRouteName.WEBEX,
        component   : <Webex />,
        transKey    : 'pageTitle.webex',
        mustbeHidden: {
            mobileOnly         : true,
            noPhone            : true,
            hostedTelephonyOnly: false,
        },
        availableInMenu: false,
    },
};

export const URL_WEBEX = {
    IOS      : 'https://apps.apple.com/us/app/cisco-spark/id833967564',
    ANDROID  : 'https://www.webex.com/content/dam/wbx/us/images/downloads/rebrand/uc.apk',
    MICROSOFT: 'https://www.webex.com/fr/downloads.html',
    MAC      : 'https://www.webex.com/fr/downloads.html',
};

export const URL_DOWNLOAD_DOKO = {
    IOS      : 'https://apps.apple.com/no/app/doko-phone/id1635524165',
    ANDROID  : 'https://play.google.com/store/apps/details?id=com.doko.android&gl=FR',
    MICROSOFT: 'https://apps.microsoft.com/store/detail/dokophone/9NGJPQ6FXGGQ?hl=en-us&gl=us',
    MAC      : 'https://doko-phone.com/soft/Doko-phone.dmg',
};
