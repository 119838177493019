export const rootVoicemailPath = 'voicemails';
export const rootDokoPath = 'doko';

/**
 * ENUM for application route names
 */
export const AppsRouteName = {
    HOME                        : '',
    TELEPHONY_SETTINGS          : 'telephony-settings',
    DOCUMENTS                   : 'documents',
    CALL_LOGS                   : 'call-logs',
    CALL_FORWARDING             : 'call-forwarding',
    CONSUMPTION_TRACKING        : 'consumption-tracking',
    CONTACTS                    : 'contacts',
    VOICEMAILS_MESSAGES         : `${rootVoicemailPath}/messages`,
    VOICEMAILS_MOBILE_SETTINGS  : `${rootVoicemailPath}/mobile-settings`,
    VOICEMAILS_LANDLINE_SETTINGS: `${rootVoicemailPath}/landline-settings`,
    PROFILE                     : 'profile',
    LOGIN                       : 'login',
    WEBEX                       : 'softphone/webex',
    DOKO_ADVERTISEMENT          : 'softphone/doko',
};

export const DokoAppsRouteName = {
    TELEPHONY_SETTINGS          : `${rootDokoPath}/telephony-settings`,
    VOICEMAILS_MESSAGES         : `${rootDokoPath}/${rootVoicemailPath}/messages`,
    VOICEMAILS_LANDLINE_SETTINGS: `${rootDokoPath}/${rootVoicemailPath}/landline-settings`,
    CALL_FORWARDING             : `${rootDokoPath}/call-forwarding`,
};

export const SEWAN_BASENAME_URL = 'sewan.fr';
