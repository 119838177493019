import { Flex } from '@ui-kit/layout';
import styled, { th } from '@xstyled/styled-components';

export const Wrapper = styled.div`
    padding: 16px;
`;

export const BaseBanner = styled.div`
    background-image: url(${(props) => props.img});
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    min-height: 200px;
    height: auto;
    max-height: 390px;
    
    @media (min-width: ${th('breakpoints.sm')}) {
        background-position-x: right;
        background-position-y: top;
        background-size: auto;
        min-height: 325px;
    }

    @media (min-width: ${th('breakpoints.lg')}) {
        background-size: 100%;
    }
`;

export const Banner = styled(BaseBanner)`
    min-height: 280px;

    @media (min-width: ${th('breakpoints.lg')}) {
        max-height: 428px;
        max-width: 510px;
        padding-right: 450px;
    }
`;

export const WrapperLicense = styled(Flex)`
    gap: inherit;

    @media (min-width: ${th('breakpoints.lg')}) {
        gap: ${th('newTheme.space.1')};
    }
`;

export const WrapperLogo = styled(Flex)`
    gap: ${th('newTheme.space.3')};

    @media (min-width: ${th('breakpoints.lg')}) {
        width: 50%;
        gap: ${th('newTheme.space.3')};
    }
`;

export const WrapperSoftPhoneDescription = styled.div`
    display: flex;
    gap: ${th('newTheme.space.4')};
    flex-direction: column;
    padding: ${th('newTheme.space.4')};
    width: 100%
`;

export const WrapperSoftphoneCard = styled.div`
    background-color: white;
    margin-top: ${th('newTheme.space.2')};
    min-width: 288px;
    

    @media (min-width: ${th('breakpoints.lg')}) {
        display: flex;
        min-height: 428px;
    }
`;

export const WrapperWebexCard = styled(WrapperSoftphoneCard)`
    min-height: 767px;
`;

export const StyledLogo = styled.div`
    cursor: pointer;
    background-image: url(${(props) => props.img});
    background-size: cover;

    height: ${(props) => props.height};
    width: ${(props) => props.width};
`;

export const WrapperDesktopLogo = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${th('newTheme.space.3')};
`;

export const WrapperQrCode = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${th('newTheme.space.3')};
`;
