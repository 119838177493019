import { useTranslation, store } from '@sewan/core';
import MainMenu from '@ui-kit/mainmenu';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFilteredConfigRoutes } from '../../hooks';
import { TEST_IDS } from '../../testIds';
import { SWrapper, StyledOverlay, StyledContainer, SMainMenuWrapper } from './style';
import { Contact, WebexMenuItem, DokoMenuItem } from './subComponents';

const Menu = () => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation('common');

    const burgerOpen = store.burger.getShowBurger();
    const {
        filteredConfigRoutes,
        hasWebex,
        hasHostedTelephony,
        hasDoko,
    } = useFilteredConfigRoutes(true);

    const computeItem = useCallback((label, to, icon) => {
        const splitPath = location.pathname.split('/');
        const splitTo = to.split('/');
        const toPath = location.search ? `${to}${location.search}` : to;

        return {
            to     : toPath,
            label,
            icon,
            onClick: () => {
                history.push({ pathname: to });
            },
            isActive: location.pathname === to || (splitPath.length > 2 && splitPath[1] === splitTo[1]),
        };
    },
    [history, location]);

    const LINKS_SUBMENU = [
        {
            title: '',
            id   : 'group-general',
            items: filteredConfigRoutes.map(
                ({ label, icon, path }) => computeItem(t(label), `/${path}`, icon),
            ),
        },
    ];

    store.burger.setHasBurger(Boolean(LINKS_SUBMENU[0]?.items?.length));

    return (
        <SWrapper>
            <StyledContainer isOpen={burgerOpen}>
                <SMainMenuWrapper onClick={() => store.burger.setShowBurger(false)}>
                    <MainMenu
                        options={LINKS_SUBMENU}
                        width="100%"
                        testId={TEST_IDS.mainMenu}
                    />
                    {hasWebex && hasHostedTelephony && <WebexMenuItem />}
                    {hasDoko && hasHostedTelephony && <DokoMenuItem />}
                    <Contact />
                </SMainMenuWrapper>
            </StyledContainer>
            <StyledOverlay isOpen={burgerOpen} onClick={() => store.burger.setShowBurger(false)} />
        </SWrapper>
    );
};

export default Menu;
