import { store, AppsRouteName, FEATURE_FLAGS_MODE, useIsDoko } from '@sewan/core';
import ToastContainer from '@ui-kit/toaster';
import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { Menu, Header, PageTitle, ScrollToTop, PwaProvider, Favicon, MainLayout } from './components';
import { PREVIOUS_PATH_KEY } from './constants';
import Routes, { NoAuthRoutes, DokoRoutes } from './routes';
import { SBody } from './styles';

const App = () => {
    const auth = store.identity.getAuth();
    const { pathname, search } = useLocation();
    const location = useLocation();
    const isDoko = useIsDoko();

    useEffect(() => {
        if (pathname !== `/${AppsRouteName.LOGIN}`) {
            window.localStorage.setItem(PREVIOUS_PATH_KEY, pathname);
        }

        if (search.includes(`${FEATURE_FLAGS_MODE}=true`)) {
            window.localStorage.setItem(FEATURE_FLAGS_MODE, 'true');
        } else {
            window.localStorage.removeItem(FEATURE_FLAGS_MODE);
        }
    }, [location, pathname, search]);

    const displayedContent = auth ? (
        <MainLayout
            content={(
                <SBody className="for-scroll">
                    <ScrollToTop />
                    <Routes />
                </SBody>
            )}
            header={<Header />}
            sidebar={<Menu />}
        />
    ) : (
        <NoAuthRoutes />
    );

    const dokoContent = (
        <SBody className="for-scroll">
            <ScrollToTop />
            <DokoRoutes />
        </SBody>
    );

    return (
        <HelmetProvider>
            <PageTitle>
                <Favicon>
                    <PwaProvider>
                        <ToastContainer />
                        {isDoko ? dokoContent : displayedContent}
                    </PwaProvider>
                </Favicon>
            </PageTitle>
        </HelmetProvider>
    );
};

export default App;
