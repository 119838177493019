import { GenericError, ERROR_TYPE, ERROR_ACTION, keycloakService, AppsRouteName, Loader } from '@sewan/core';
import { TIME } from '@sewan/core/src/constants';
import React, { Suspense } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { configNoAuthRoutes, PREVIOUS_PATH_KEY } from './constants';
import { useFilteredConfigRoutes, useFilteredDokoRoutes } from './hooks';

const Routes = () => {
    const { pathname } = useLocation();
    const {
        filteredConfigRoutes,
        noPhone,
        hasAdvancedMobile,
        hasMobile,
        hasHostedTelephony } = useFilteredConfigRoutes();

    if (keycloakService.isTokenExpired()) {
        keycloakService.logout();
    } else {
        keycloakService.updateToken(TIME.quaterOfHour);
    }

    if (keycloakService.getToken() && pathname === `/${AppsRouteName.LOGIN}`) {
        const previousPath = window.localStorage.getItem(PREVIOUS_PATH_KEY) || `/${AppsRouteName.HOME}`;

        return <Redirect to={previousPath} />;
    }

    if ((noPhone || (!(hasAdvancedMobile || hasMobile || hasHostedTelephony)))
        && pathname === `/${AppsRouteName.HOME}`
    ) {
        return <Redirect to={AppsRouteName.PROFILE} />;
    }

    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                {
                    filteredConfigRoutes.map(({ path, component, exact }) => (
                        <Route key={path} exact={exact} path={`/${path}`}>
                            {component}
                        </Route>
                    ))
                }
                <Route>
                    <GenericError content={ERROR_TYPE.notFound} />
                </Route>
            </Switch>
        </Suspense>
    );
};

export const NoAuthRoutes = () => {
    if (keycloakService.getToken()) {
        const previousPath = window.localStorage.getItem(PREVIOUS_PATH_KEY) || `/${AppsRouteName.HOME}`;

        return <Redirect to={previousPath} />;
    }

    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                {
                    configNoAuthRoutes.map(({ path, component, exact }) => (
                        <Route key={path} exact={exact} path={[`/${path}`, `/${AppsRouteName.LOGIN}`]}>
                            {component}
                        </Route>
                    ))
                }
                <Route path="*">
                    <Redirect to="/login" />
                </Route>
            </Switch>
        </Suspense>
    );
};

export const DokoRoutes = () => {
    const { filteredDokoRoutes } = useFilteredDokoRoutes();

    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                {
                    filteredDokoRoutes.map(({ path, component, exact }) => (
                        <Route key={path} exact={exact} path={`/${path}`}>
                            {component}
                        </Route>
                    ))
                }
                <Route>
                    <GenericError content={ERROR_TYPE.notFound} action={ERROR_ACTION.reload} />
                </Route>
            </Switch>
        </Suspense>
    );
};

export default Routes;
