import { ERROR_SERVICES, store, useEnvironment } from '@sewan/core';
import { configRoutes, configRoutesSoftphones, SOFTPHONES, configDokoRoutes } from '../constants';

export const useFilteredConfigRoutes = (isMenu) => {
    const {
        mobile: hasMobile,
        landline: hasLandline,
        advancedMobile: hasAdvancedMobile,
        hostedTelephony: hasHostedTelephony,
        eSimCard: hasESimCard,
        webex: hasWebex,
        doko: hasDoko,
    } = store.availableServices.getAvailableServices();

    const errorsFromInitServices = store.availableServices.getErrorsServices();
    const hasErrorMobile = errorsFromInitServices.includes(ERROR_SERVICES.MOBILE_LINE_LIMITED_INFORMATION);
    const errorHostedTelephony = errorsFromInitServices.includes(ERROR_SERVICES.HOSTED_TELEPHONY_ACCOUNT);

    const disabledModules = store.customization.getDisabledModules();
    const noPhone = (!hasMobile && !hasErrorMobile) && (!hasLandline && !errorHostedTelephony);
    const { isProductionEnvironment } = useEnvironment();
    const mobileOnly = hasMobile && !hasLandline && !hasAdvancedMobile && !hasHostedTelephony;

    const filteredConfigRoutes = configRoutes.filter((item) => !(
        disabledModules.includes(item.module)
        || (isMenu && !item.availableInMenu)
        || (isProductionEnvironment && item.mustbeHidden.production)
        || ((item.mustbeHidden.mobileOnly && mobileOnly) && !(item.hasESimCard && hasESimCard))
        || ((item.mustbeHidden.noPhone && noPhone) && !(hasHostedTelephony && !item.mustbeHidden.hostedTelephonyOnly))
    ));

    if (!isMenu) {
        if (hasWebex) {
            filteredConfigRoutes.push(configRoutesSoftphones[SOFTPHONES.WEBEX]);
        }
        if (hasDoko) {
            filteredConfigRoutes.push(configRoutesSoftphones[SOFTPHONES.DOKO]);
        }
    }

    return {
        filteredConfigRoutes,
        noPhone: noPhone && !hasHostedTelephony,
        hasWebex,
        hasHostedTelephony,
        hasAdvancedMobile,
        hasMobile,
        hasDoko,
    };
};

export const useFilteredDokoRoutes = () => {
    const hasDoko = store.hostedTelephony.getHasDoko();
    const { hostedTelephony: hasHostedTelephony } = store.availableServices.getAvailableServices();

    return {
        filteredDokoRoutes: hasDoko && hasHostedTelephony ? configDokoRoutes : [],
    };
};
