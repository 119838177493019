import { AppsRouteName } from '@sewan/core';
import { WebexLogo } from '@ui-kit/logo';
import React from 'react';

import MenuItem from '../MenuItem';
import { SOFTPHONES } from '#constants';

const WebexMenuItem = () => (
    <MenuItem
        logo={<WebexLogo height={48} width={48} />}
        pathname={`/${AppsRouteName.WEBEX}`}
        type={SOFTPHONES.WEBEX}
    />
);

export default WebexMenuItem;
