import { AppsRouteName, keycloakService, store, useTranslation } from '@sewan/core';
import { DEFAULT_LANGUAGE_CODE, LANGUAGES } from '@sewan/core/src/constants/i18n';
import Avatar from '@ui-kit/avatar';
import * as icons from '@ui-kit/icon';
import UserMenu from '@ui-kit/usermenu';
import React, { useCallback, useMemo } from 'react';
import { generate } from 'shortid';
import { PREVIOUS_PATH_KEY } from '../../../constants';
import { MENUS_IDS } from '../constants';
import { SNavLinkLink } from '../styles';

const { Globe1Icon, ChevronRightIcon, SettingsIcon } = icons;

const testIds = {
    name        : 'main_header-avatar-menu_avatar_name',
    image       : 'main_header-avatar-menu_avatar_image',
    defaultImage: 'main_header-avatar-menu_avatar_default-image',
};

const AvatarMenu = () => {
    const { t } = useTranslation(['common', 'profile']);
    const { i18n } = useTranslation();

    const currentLanguageCode = i18n.language;

    const currentLanguage = LANGUAGES[currentLanguageCode]
        ? LANGUAGES[currentLanguageCode] : LANGUAGES[DEFAULT_LANGUAGE_CODE];

    const identity = store.identity.getIdentity();

    const profilePath = `/${AppsRouteName.PROFILE}`;

    const customItems = useCallback(
        ({ toggleVisibleMenu }) => (
            <UserMenu.MenuSection>
                <UserMenu.MenuItem
                    content={currentLanguage}
                    leftIcon={<Globe1Icon />}
                    menuId={MENUS_IDS.LANGUAGE}
                    rightIcon={<ChevronRightIcon />}
                    onClick={toggleVisibleMenu}
                />
                <UserMenu.MenuItem
                    content={<SNavLinkLink to={profilePath}>{t('profile:profile.profileSettings')}</SNavLinkLink>}
                    leftIcon={<SettingsIcon />}
                    menuId="profile-link"
                    onClick={() => {}}
                />
            </UserMenu.MenuSection>
        ),
        [currentLanguage, profilePath, t],
    );

    const userInfo = useMemo(
        () => ({
            email    : identity.email,
            firstName: identity.firstName,
            lastName : identity.lastName,
            imageSrc : identity.avatarUrl ? identity.avatarUrl : null,
        }),
        [identity.avatarUrl, identity.email, identity.firstName, identity.lastName],
    );

    const trigger = useMemo(
        () => (
            <Avatar
                key={generate()}
                image={userInfo.imageSrc}
                name={`${userInfo.firstName} ${userInfo.lastName}`.trim()}
                testIds={testIds}
            />
        ),
        [userInfo],
    );

    const handleSelectLanguage = useCallback((newLanguage) => {
        i18n.changeLanguage(newLanguage);
    }, [i18n]);

    const languageOptions = useMemo(
        () => Object.entries(LANGUAGES).reduce((acc, [value, label]) => {
            acc.push({ value, label });

            return acc;
        }, []),
        [],
    );

    const customPopovers = useCallback(
        ({ visibleMenu, returnToMainMenu }) => (
            <>
                {/* Language popover */}
                <UserMenu.SubMenuList
                    opened={visibleMenu === MENUS_IDS.LANGUAGE}
                    options={languageOptions}
                    returnText={t('header.avatarNav.return')}
                    value={currentLanguageCode}
                    onClickReturn={returnToMainMenu}
                    onSelect={handleSelectLanguage}
                />
            </>
        ),
        [handleSelectLanguage, languageOptions, t, currentLanguageCode],
    );

    const logout = () => {
        window.localStorage.removeItem(PREVIOUS_PATH_KEY);
        keycloakService.logout();
    };

    return (
        <UserMenu
            customItems={customItems}
            customPopovers={customPopovers}
            logoutText={t('header.avatarNav.logout')}
            trigger={trigger}
            userInfo={userInfo}
            onLogoutClick={logout}
        />
    );
};

export default AvatarMenu;
