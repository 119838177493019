import { useTranslation } from '@sewan/core';
import Typography from '@ui-kit/typography';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { SOFTPHONES } from '../../../constants';
import { GridThirdPartyMenuItemLogo, GridThirdPartyMenuItem } from '../style';

import { TEST_IDS } from '#testIds';

const MenuItem = ({
    logo,
    type,
    pathname,
}) => {
    const { t } = useTranslation('common');
    const history = useHistory();

    const location = useLocation();
    const { pathname : currentPathname } = location;

    return (
        <GridThirdPartyMenuItem
            isActive={currentPathname === pathname}
            onClick={() => {
                history.push({
                    pathname,
                });
            }}
        >
            <GridThirdPartyMenuItemLogo>
                {logo}
            </GridThirdPartyMenuItemLogo>
            <div
                style={{
                    gridArea: 'service',
                }}
            >
                <Typography
                    color="primary"
                    size="xs"
                    weight="semiBold"
                    testId={TEST_IDS[`${type}MenuItem`].typography.service}
                >
                    {t(`softphones.${type}.menu.service`)}
                </Typography>
            </div>
            <div
                style={{
                    gridArea: 'title',
                }}
            >
                <Typography
                    color="greys.800"
                    size="md"
                    weight="medium"
                    testId={TEST_IDS[`${type}MenuItem`].typography.title}
                >
                    {t(`softphones.${type}.title`)}
                </Typography>
            </div>
            <div
                style={{
                    gridArea: 'description',
                }}
            >
                <Typography
                    color="greys.600"
                    size="xs"
                    weight="regular"
                    testId={TEST_IDS[`${type}MenuItem`].typography.description}
                >
                    {t(`softphones.${type}.menu.description`)}
                </Typography>
            </div>
        </GridThirdPartyMenuItem>
    );
};

MenuItem.propTypes = {
    logo    : PropTypes.node.isRequired,
    pathname: PropTypes.string.isRequired,
    type    : PropTypes.oneOf(Object.values(SOFTPHONES)).isRequired,
};

export default MenuItem;
