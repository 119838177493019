export const TEST_IDS = {
    mainMenu: 'main_main-menu',
    textLink: {
        container: 'main_menu_textlink_container-contact',
        leftIcon : 'main_menu_textlink_left-icon-contact',
    },
    webexMenuItem: {
        typography: {
            title      : 'main_webex-menu-item_typography_title',
            service    : 'main_webex-menu-item_typography_service',
            description: 'main_webex-menu-item_typography_description',
        },
    },
    dokoMenuItem: {
        typography: {
            title      : 'main_doko-menu-item_typography_title',
            service    : 'main_doko-menu-item_typography_service',
            description: 'main_doko-menu-item_typography_description',
        },
    },
    webex: {
        typography: {
            title              : 'main_webex_typography_title',
            description        : 'main_webex_typography_description',
            subDescription     : 'main_webex_typography_sub-description',
            downloadDescription: 'main_webex_typography_download-description',
            license            : {
                description: 'main_webex_license_typography_description',
                title      : 'main_webex_license_typography_title',
            },
        },
        radioButtonGroup: 'main_webex_license_radio-button-group',
        radioButton     : {
            premium : 'main_webex_license_radio-button_premium',
            standard: 'main_webex_license_radio-button_standard',
            basic   : 'main_webex_license_radio-button_basic',
        },
    },
    doko: {
        typography: {
            title              : 'main_doko_typography_title',
            description        : 'main_doko_typography_description',
            subDescription     : 'main_doko_typography_sub-description',
            downloadDescription: 'main_doko_typography_download-description',
            qrCodeDescription  : 'main_doko_typography_qrCode-description',
        },
    },
};
