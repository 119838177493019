import { useQuery } from '@apollo/client';
import { useTranslation, store, onAndroidDevice, onIOSDevice, getDokoDeviceConfig } from '@sewan/core';
import { useCurrentBreakpoint } from '@sewan-selfcare/responsive-system';
import Typography from '@ui-kit/typography';
import { QRCodeCanvas } from 'qrcode.react';
import React from 'react';
import {
    Wrapper,
    BaseBanner,
    WrapperLogo,
    WrapperSoftphoneCard,
    WrapperSoftPhoneDescription,
    StyledLogo,
    WrapperDesktopLogo,
    WrapperQrCode,
} from './styles';

import DokoBanner from '#assets/images/doko-banner.png';
import AndroidLogo from '#assets/images/logo-app-android.svg';
import AppleLogo from '#assets/images/logo-app-ios-black.svg';
import MicrosoftLogo from '#assets/images/logo-app-microsoft-black.svg';
import { URL_DOWNLOAD_DOKO } from '#constants';
import { TEST_IDS } from '#testIds';

const DokoAd = () => {
    const { t } = useTranslation('common');
    const voiceAccounts = store.hostedTelephony.getHostedTelephonyAccounts();

    const { currentBreakpoint } = useCurrentBreakpoint();
    const isMobileView = [0, 1, 2].includes(currentBreakpoint);

    const { error, data, loading } = useQuery(getDokoDeviceConfig, {
        variables: {
            voiceAccountId: voiceAccounts[0]?.id,
        },
        skip: !voiceAccounts[0],
    });

    return (
        <Wrapper>
            <Typography
                weight="semiBold"
                size="xl"
                color="greys.800"
                testId={TEST_IDS.doko.typography.title}
            >

                {t('softphones.doko.title')}
            </Typography>
            <WrapperSoftphoneCard>
                { isMobileView && (
                    <BaseBanner img={DokoBanner} />
                )}

                <WrapperSoftPhoneDescription>
                    <Typography
                        weight="semiBold"
                        size="lg"
                        color="greys.800"
                        testId={TEST_IDS.doko.typography.description}
                    >
                        {t('softphones.doko.page.description')}
                    </Typography>
                    <Typography
                        weight="medium"
                        size="sm"
                        color="greys.800"
                        testId={TEST_IDS.doko.typography.subDescription}
                    >
                        {t('softphones.doko.page.subDescription')}
                    </Typography>
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <WrapperLogo
                            flexDirection="column"
                            alignItems="start"
                        >
                            <Typography
                                weight="regular"
                                size="md"
                                color="greys.800"
                                testId={TEST_IDS.doko.typography.downloadDescription}
                            >
                                {t('softphones.doko.page.downloadDescription')}
                            </Typography>
                            <WrapperDesktopLogo>
                                {isMobileView && onAndroidDevice() && (
                                    <StyledLogo
                                        img={AndroidLogo}
                                        width="40px"
                                        height="40px"
                                        onClick={() => window.open(URL_DOWNLOAD_DOKO.ANDROID, '_blank')}
                                    />
                                )}
                                {isMobileView && onIOSDevice() && (
                                    <StyledLogo
                                        img={AppleLogo}
                                        width="40px"
                                        height="40px"
                                        onClick={() => window.open(URL_DOWNLOAD_DOKO.IOS, '_blank')}
                                    />
                                )}
                                {
                                    !isMobileView && (
                                        <>
                                            <StyledLogo
                                                img={MicrosoftLogo}
                                                width="40px"
                                                height="40px"
                                                onClick={() => window.open(URL_DOWNLOAD_DOKO.MICROSOFT, '_blank')}
                                            />
                                            <StyledLogo
                                                img={AppleLogo}
                                                width="40px"
                                                height="40px"
                                                onClick={() => window.open(URL_DOWNLOAD_DOKO.MAC, '_blank')}
                                            />
                                        </>
                                    )
                                }
                            </WrapperDesktopLogo>
                        </WrapperLogo>
                        {!loading && !error && !isMobileView && (
                            <WrapperQrCode>
                                <Typography
                                    weight="regular"
                                    size="md"
                                    color="greys.800"
                                    testId={TEST_IDS.doko.typography.qrCodeDescription}
                                >
                                    {t('softphones.doko.page.qrCodeDescription')}
                                </Typography>
                                <QRCodeCanvas
                                    value={data?.voiceAccount?.getDokoDeviceConfig?.qrCode}
                                    size={isMobileView ? 125 : 149}
                                />
                            </WrapperQrCode>
                        )}
                    </div>
                </WrapperSoftPhoneDescription>
                { !isMobileView && (
                    <BaseBanner img={DokoBanner} />
                )}
            </WrapperSoftphoneCard>
        </Wrapper>
    );
};

export default DokoAd;
